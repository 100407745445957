import React from "react";
import "./App.css";
import Navbar from "../src/components/Navbar";
import Projects from "../src/components/Projects";
import HomeView from "./components/HomeView";
import About from "./components/About";
import Contact from "./components/Contact";

function App() {
  return (
    <div className="App">
      <Navbar />
      <HomeView />
      <About />
      <Projects />
      <Contact />
    </div>
  );
}

export default App;
