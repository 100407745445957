import React from "react";

export default function About() {
  return (
    <div id="about-me" className="about">
      <div className="top-border-right "></div>
      <div className="top-border-mobile">
        <div className="left-triangle"></div>
        <div className="right-triangle"></div>
      </div>
      <div className="aboutContent">
        <h2>About me</h2>
        <p>I'm a frontend developer, UX/UI enthusiast and music lover.</p>
        <p>
          I got a translation degree a few years ago but I discovered that my
          passion was web development.
        </p>
        <p>So here I am to help you translate your ideas into code!</p>
        <p>Take a look at some of my projects below!</p>
      </div>
      <div className="bottom-border-left"></div>
    </div>
  );
}
