import React from "react";

export default function Navbar() {
  let widthMatch = window.matchMedia("(max-width: 600px)");

  const handleBurgerMenu = () => {
    let navbar = document.getElementById("navbar");
    let navbarContent = document.getElementById("navbar-content");
    let e = document.getElementById("hamburger");
    document.body.classList.toggle("burgerOpen");
    e.classList.toggle("change");
    navbar.classList.toggle("zIndexHandler");
    navbarContent.style.display =
      navbarContent.style.display === "block" ? "none" : "block";
  };

  const closeBurgerMenu = () => {
    let navbar = document.getElementById("navbar");
    let navbarContent = document.getElementById("navbar-content");
    let e = document.getElementById("hamburger");
    document.body.classList.remove("burgerOpen");
    navbar.classList.remove("zIndexHandler");
    e.classList.remove("change");
    if (widthMatch.matches) {
      navbarContent.style.display = "none";
    }
  };

  widthMatch.addEventListener("change", (mm) => {
    let navbarContent = document.getElementById("navbar-content");
    let navbar = document.getElementById("navbar");

    if (mm.matches) {
      navbarContent.style.display = "none";
    } else {
      navbarContent.style.display = "block";
      navbar.style.position = "";
    }
  });

  return (
    <div id="navbar" className="navbar">
      <div
        id="hamburger"
        onClick={handleBurgerMenu}
        className="hamburger zIndexHandler"
      >
        <div className="line1"></div>
        <div className="line2"></div>
        <div className="line3"></div>
      </div>
      <div id="navbar-content" className="navbar-content">
        <div className="navButtons">
          <ul>
            <li>
              <a onClick={closeBurgerMenu} href="#about-me">
                About
              </a>
            </li>

            <li>
              <a onClick={closeBurgerMenu} href="#projects">
                Projects
              </a>
            </li>

            <li>
              <a onClick={closeBurgerMenu} href="#contact">
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
