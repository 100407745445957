import React from "react";

export default function HomeView() {
  return (
    <div className="home-view">
      <div className="homeContent">
        <p>
          <small>
            THERE IS ALWAYS A WAY{" "}
            <span
              role="img"
              aria-label="arrow down emoji"
              className="arrowDown"
            >
              🔽
            </span>
          </small>
        </p>
        <h1>
          Hi there. I'm Gonzalo, a Web Developer{" "}
          <span role="img" aria-label="computer emoji">
            💻
          </span>
        </h1>
        <h1>Would you like to work with me?</h1>
      </div>
    </div>
  );
}
